import { Component } from '@angular/core';
import { SearchService } from '../search.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent {
  searchQuery: string = '';

  constructor(private searchService: SearchService, private router: Router) {}

  searchMovies(): void {
    this.searchService.searchMovies(this.searchQuery);
  }

  disconnectUser() {
    this.searchService.logout();
    window.location.reload();
  }
  toggleNavbarMenu() {
    const navbarMenu = document.querySelector('.navbar-menu') as HTMLElement;
    navbarMenu.classList.toggle('active');
  }
  

  menuOpen = false;

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }


}


