import { Component, OnInit } from '@angular/core';
import { SearchService } from '../search.service';
import { Movie, filmAggiunto } from '../home/home.component';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-filmacquistati',
  templateUrl: './filmacquistati.component.html',
  styleUrls: ['./filmacquistati.component.css']
})
export class FilmacquistatiComponent implements OnInit {

  filmAggiunti: filmAggiunto[] = []; /* dichiaro la variabile per visualizzare i film sull html  */
  



  constructor(private searchService: SearchService) {  }
  ngOnInit(): void {
    this.OttieniFilmAcquistati();
  }

  OttieniFilmAcquistati() {
    this.searchService.ottieniListaAggiunti()
      .subscribe({
        next: (res: filmAggiunto[]) => {
          this.filmAggiunti = res;
        }
      })

  } 

  getMovieDetail(id: number) {

  }


  
}
