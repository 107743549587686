import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-contatti',
  templateUrl: './contatti.component.html',
  styleUrls: ['./contatti.component.css']
})
export class ContattiComponent implements OnInit {
  contattiForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this.contattiForm = this.fb.group({
      nome: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      messaggio: ['', Validators.required]
    });
  }

  ngOnInit(): void {
  }

  onSubmit(): void {
    if (this.contattiForm.valid) {
      console.log('Dati del modulo contatti:', this.contattiForm.value);
      // Qui puoi inviare i dati del modulo al server o eseguire altre operazioni
    }
  }
}