import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ContattiComponent } from './contatti/contatti.component';
import { FilmacquistatiComponent } from './filmacquistati/filmacquistati.component';
/* import { Login2Component } from './login2/login2.component'; */

const routes: Routes = [

{path:'home', component:HomeComponent},  
{path: '', redirectTo: '/home', pathMatch: 'full'}, 
{path:'contatti', component:ContattiComponent}, //localhost:4200/contatti
/*   {path:'login2', component:Login2Component} */
{path:'filmacquistati', component: FilmacquistatiComponent}


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }


