
<div class="movies-container">

  <div class="movie-card" *ngFor="let acquistato of filmAggiunti">
    <img [src]="'https://image.tmdb.org/t/p/w500' + acquistato.film.poster_path" 
       (click)="getMovieDetail(acquistato.film.id)" />
    <div class="movie-info">
      <h3>{{ acquistato.film.title }}</h3>
      <h6> Anno: {{ acquistato.film.release_date | date:'yyyy' }} </h6>
      <h6>id: {{acquistato.film.id}}</h6>

      <p></p>
  
    </div>
  </div>

</div>
