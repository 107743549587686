import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MovieDetail } from '../home/home.component';

@Component({
  selector: 'app-movie-detail',
  templateUrl: './movie-detail.component.html',
  styleUrls: ['./movie-detail.component.css']
})
export class MovieDetailComponent {
  @Input() selectedMovie: MovieDetail | null = null; /* Questo è un decoratore di input. Questo significa che il componente padre può passare un oggetto di tipo MovieDetail a questo componente. Se non viene passato nulla, il valore di default sarà null. */
  @Output() hideDetail = new EventEmitter<void>();
/* Questo è un decoratore di output. Questo componente emette un evento quando si verifica un'azione specifica. In questo caso, l'evento non trasmette alcun dato (void), si limita a notificare l'evento di "nascondere il dettaglio". */
  constructor(private sanitizer: DomSanitizer) {}

  get safeUrl(): SafeResourceUrl | null {
    return this.selectedMovie?.trailerUrl ? this.sanitizer.bypassSecurityTrustResourceUrl(this.selectedMovie.trailerUrl) : null;
    /* Questo è un getter che restituisce un URL sicuro (o null). Se esiste un URL per il trailer del film, viene utilizzato il DomSanitizer per "pulire" l'URL e renderlo sicuro per l'uso. Se non c'è un URL, restituisce null. */
  }
}
