<div class="navbar-container">
  <nav class="navbar">
    <div class="toggle-button" (click)="toggleMenu()">
      <span class="bar"></span>
      <span class="bar"></span>
      <span class="bar"></span>
    </div>

    <ul [ngClass]="{ active: menuOpen }" class="mobile-links">
      <li><a [routerLink]="['/home']">HOME</a></li>
      <li><a [routerLink]="['/contatti']">CONTATTI</a></li>
  <!--     <li><a [routerLink]="['/filmacquistati']">ACQUISTATI</a></li> -->
    <!--   <li><a (click)="disconnectUser()" [routerLink]="['/home']">LOGOUT</a></li> -->
    </ul>

    <div class="search-container">
      <input [(ngModel)]="searchQuery" (input)="searchMovies()" placeholder="Cerca film..." />
    </div>
  </nav>
</div>