import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SearchService } from '../search.service';
import { map, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';


export type Movie = { /* In questa sezione vengono definiti due tipi di dati. Movie è una semplice interfaccia che rappresenta un film con alcune proprietà come id, title, poster_path e release_date. MovieDetail rappresenta i dettagli completi di un film, inclusi overview (descrizione), genres (generi), credits (crediti del film), e opzionalmente trailerUrl (URL del trailer). */
  id: number;
  title: string;
  poster_path: string;
  release_date: string;
};

export interface MovieDetail {
  title: string;
  poster_path: string;
  release_date: string;
  overview: string;
  genres: {
    id: number;
    name: string;
  }[];
  credits: {
    cast: {
      name: string;
    }[];
  };
  trailerUrl?: string;
}

export interface User { /* recupera utente */
  email: string;
  id: number;
  nome: string;
}

export interface LoggedUser { /* recupera il token */
  user: User;
  accessToken: string;
}

export interface filmAggiunto { /*  creo un interfaccia a posta per i film aggiunti 
con film userid e id  */
  film: Movie;
  UserId: number;
  id: number;
}

@Component({   
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {  /*. Il componente implementa l'interfaccia OnInit e ha diverse proprietà come selectedMovie (film selezionato), currentPage (pagina corrente), apiUrl (URL per recuperare i dati dei film) e movies (array di film).

Successivamente, viene definito il costruttore del componente che inizializza le dipendenze come HttpClient, SearchService e Router. */
  selectedMovie: MovieDetail | null = null;
  currentPage = 1;
  apiUrl = `https://api.themoviedb.org/3/movie/popular?api_key=f76d89b2bb26ca527b01f266350fe0ec&language=it-IT&page=${this.currentPage}`;
  movies: Movie[] = [];

  constructor(private http: HttpClient, private searchService: SearchService,) {}




  ngOnInit() {  /* 
  fetchData() recupera i dati dei film dalla API e li aggiorna nel componente.
  Il componente si iscrive all'observable movies$ fornito dal SearchService. Ogni volta che ci sono nuovi dati di film disponibili, vengono assegnati alla proprietà movies del componente.
  Viene aggiunto un listener all'evento di scorrimento della finestra. */
    this.fetchData();
    this.searchService.movies$.subscribe((movies) => {
      this.movies = movies;
    });

    window.addEventListener('scroll', () => {
      const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        this.altrivideo();
      }
    });
  }

  altrivideo() {
    this.currentPage++;
    this.apiUrl = `https://api.themoviedb.org/3/movie/popular?api_key=f76d89b2bb26ca527b01f266350fe0ec&language=it-IT&page=${this.currentPage}`;
    this.fetchData();
  }

  fetchData() {  /* Il metodo fetchData() utilizza il modulo HttpClient per effettuare una richiesta GET all'API dei film. I dati restituiti vengono quindi elaborati: se la proprietà results esiste nell'oggetto di dati, vengono aggiunti i nuovi film all'array movies utilizzando il servizio SearchService. In caso contrario, l'array movies viene azzerato. */
    this.http.get<any>(this.apiUrl).subscribe( 
      (data) => {
        if (data.results) {
          this.searchService.updateMovies([...this.movies, ...data.results]);
         
        } else {
          this.searchService.updateMovies([]);
        }
      },
      (error) => {
        console.error('errore:', error);
      }
    );
  }

  getMovieDetail(movieId: number) { /*  Il metodo getMovieDetail(movieId: number) viene chiamato quando si desidera ottenere i dettagli completi di un film specifico. Utilizza il servizio SearchService per ottenere i dettagli del film chiamando getMovieDetail(movieId). Successivamente, viene effettuata una chiamata HTTP per ottenere i video associati al film. Se viene trovato un video di tipo "Trailer", l'URL del trailer viene assegnato alla proprietà trailerUrl dell'oggetto detail. Infine, i dettagli del film vengono mostrati chiamando il metodo showMovieDetail(detail).*/


    this.searchService.getMovieDetail(movieId).pipe(
      switchMap(detail => {
        return this.http.get(`https://api.themoviedb.org/3/movie/${movieId}/videos?api_key=f76d89b2bb26ca527b01f266350fe0ec&language=en-US`).pipe(
          map((res: any) => {
            const trailerKey = res.results.find((video: any) => video.type === 'Trailer')?.key;
            if (trailerKey) {
              detail.trailerUrl = `https://www.youtube.com/embed/${trailerKey}`;
            }
            return detail;
          })
        );
      })
    ).subscribe(
      detail => {
        this.showMovieDetail(detail);
      },
      error => {
        console.error('errore:', error);
      }
    );
  }

  showMovieDetail(detail: MovieDetail) {
    this.selectedMovie = detail;
  }

  hideMovieDetail() {
    this.selectedMovie = null;
  }

 /*  aggiungiAllaLista(movie: Movie) {
    this.searchService.aggiungiAllaTuaLista(movie);

  } */





}
