import { Component } from '@angular/core';
import { SearchService } from '../search.service';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login2',
  templateUrl: './login2.component.html',
  styleUrls: ['./login2.component.css']
})
export class Login2Component {
  constructor (private servicesearch:SearchService, private router: Router){}

  formVisibile: Boolean = true;

   inviaDati(form: NgForm) {
    const eMail = form.value['e-mail']
    const password = form.value['password']
    this.servicesearch.accedi(form.value).subscribe({
      next: result => {
        localStorage.setItem("user", JSON.stringify(result));/* salvo nel local storage 
        l utente che si è appena loggato  */
        window.location.reload();
      },
      error: (err) => {
        alert(err.error)
      },
      complete: () => {
       
      }
    }) 
  }
/*  funzione per la registrane dei nuovi utenti */
  registrati(form: NgForm) {
    const eMail = form.value['e-mail']
    const password = form.value['password']
    this.servicesearch.registrati(form.value).subscribe({
      next: result => {
        localStorage.setItem("user", JSON.stringify(result));/* serve per entrare cliccando su 
        registra senza passare da login  */
        window.location.reload();
      },
      error: (err) => {
        alert(err.error)
      },
      complete: () => {
       
      }
    }) 
  }

  vaiaRegistrazione() {
    this.formVisibile = !this.formVisibile;
  }


}


