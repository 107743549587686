<footer>
    <div class="container">
      Luca Di Ferdinando by lucadiferdinando.uno © 2023 - Contattaci | dmca| Guida per trovarci | Feed
   
    <ul>
    <li>
      <a href="https://www.facebook.com/your_username" target="_blank">
        <i class="fa fa-facebook" aria-hidden="true"></i>
      </a>
    </li>
    <li>
      <a href="https://www.instagram.com/your_username" target="_blank">
        <i class="fa fa-instagram" aria-hidden="true"></i>
      </a>
    </li>
    <li>
      <a href="https://twitter.com/your_username" target="_blank">
        <i class="fa fa-twitter" aria-hidden="true"></i>
      </a>
    </li>
    <li>
      <a href="https://www.youtube.com/channel/your_channel_id" target="_blank">
        <i class="fa fa-youtube" aria-hidden="true"></i>
      </a>
    </li>
  </ul>
  </div>
  </footer>
  