<br><br><br><br>
<!-- [ngClass 'visibile'<-- nome classe : formVisible <--- variabile che rende visibile la classe] -->
<h1 [ngClass]="{'visibile': formVisibile}"><img src="assets/logo-tipo2.png" alt="Logo">
</h1>

<!-- modulo login  -->


<div class="container-login" [ngClass]="{'visibile': formVisibile}">
  <form #f="ngForm" (ngSubmit)="inviaDati(f)">
    <div class="mb-3">
      <label for="email" class="form-label">E-mail</label>
      <input type="email" class="form-control" id="email" name="email" placeholder="la tua mail" required
        pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" (blur)="r.controls['email'].markAsTouched()" ngModel>
      <div *ngIf="f.controls['email']?.invalid && (f.controls['email']?.dirty || f.controls['email']?.touched)"
        class="form-text text-danger">
        Il campo è obbligatorio; Deve essere correttamente formattato
      </div>
    </div>

    <div class="mb-3">
      <label for="password" class="form-label">Password</label>
      <input type="password" class="form-control" id="password" name="password" placeholder="la tua password" required
        (blur)="f.controls['password'].markAsTouched()" ngModel>
      <div *ngIf="f.controls['password']?.invalid && (f.controls['password']?.dirty || f.controls['password']?.touched)"
        class="form-text text-danger">
        Il campo è obbligatorio; Deve essere correttamente formattato
      </div>
    </div>

    <button type="submit" class="btn btn-primary" [disabled]="f.invalid">ENTRA</button>
    <button type="button" class="btn btn-primary btn-align-right" (click)="vaiaRegistrazione()">REGISTRATI</button>


  </form>
</div>

<!-- Registration Form -->

<h1 [ngClass]="{'visibile': !formVisibile}">REGISTRATI <img src="assets/logo-tipo2.png" alt="Logo">
</h1>
<div class="container-registrazione" [ngClass]="{'visibile': !formVisibile}">
  <form #r="ngForm" (ngSubmit)="registrati(r)">
    <div class="mb-3">
      <label for="email-reg" class="form-label">E-mail</label>
      <input type="email" class="form-control" id="email-reg" name="email" placeholder="la tua mail" required
        pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" (blur)="r.controls['email'].markAsTouched()" ngModel>
      <div *ngIf="r.controls['email-reg']?.invalid && (r.controls['email-reg']?.dirty || r.controls['email']?.touched)"
        class="form-text text-danger">
        Il campo è obbligatorio; Deve essere correttamente formattato
      </div>
    </div>

    <div class="mb-3">
      <label for="password-reg" class="form-label">Password</label>
      <input type="password" class="form-control" id="password-reg" name="password" placeholder="la tua password"
        required (blur)="r.controls['password'].markAsTouched()" ngModel>
      <div
        *ngIf="r.controls['password-reg']?.invalid && (r.controls['password-reg']?.dirty || r.controls['password']?.touched)"
        class="form-text text-danger">
        Il campo è obbligatorio; Deve essere correttamente formattato
      </div>
    </div>

    <button type="submit" class="btn btn-primary" [disabled]="r.invalid">INVIA</button>
    <button type="button" class="btn btn-primary btn-align-right" (click)="vaiaRegistrazione()">RITORNA AL
      LOGIN</button>
  </form>
</div>

<br><br><br><br><br><br>


<!--   <h2>Vaffanculo</h2> -->