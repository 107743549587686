import { Component, OnInit } from '@angular/core';
import { SearchService } from './search.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'ver1';


  constructor(public searchService: SearchService) {  }

  ngOnInit(): void {
    ;/*  Viene chiamato automaticamente da Angular dopo l'inizializzazione del componente. In questo metodo, viene chiamato un altro metodo this.getUser(). */
  }

}

