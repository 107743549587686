

<div class="movie-detail" *ngIf="selectedMovie">
  <button class="btn btn-primary close-button" (click)="hideDetail.emit()">X</button>

  <div class="detail-content">

    <h2>{{ selectedMovie.title }}</h2>
    <img [src]="'https://image.tmdb.org/t/p/w500' + selectedMovie.poster_path" alt="{{ selectedMovie.title }}" />
   
    <h2>Trama</h2>
    <p> {{ selectedMovie.overview }}</p>



    <div *ngIf="selectedMovie">
   
      <h2>Trailer</h2>
      <div class="trailer">
      <iframe *ngIf="safeUrl" width="750" height="415" [src]="safeUrl" frameborder="0" autoplay="1" allowfullscreen></iframe>
      <p *ngIf="!safeUrl">Trailer non disponibile per questo film.</p>
      </div>

    <h2>Anno</h2><p> {{ selectedMovie.release_date | date:'yyyy' }}</p>

    <h2>Genere</h2>
    <p *ngFor="let genre of selectedMovie.genres">{{ genre.name }}</p>

    <h2>Cast:</h2>
    
    
    <div class="actor-grid" *ngIf="selectedMovie">
      <div *ngFor="let actor of selectedMovie.credits.cast">{{ actor.name }}</div>
  </div>
   

  


  