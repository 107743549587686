import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
/* Injectable è utilizzato per marcare una classe come un servizio che può essere iniettato in altri componenti o servizi all'interno dell'applicazione Angular. In questo caso, viene applicato alla classe SearchService. */
import { Subject, Observable, of, EMPTY } from 'rxjs'; 
/*  Questa riga importa le classi Subject e Observable dal pacchetto rxjs (Reactive Extensions for JavaScript). Subject e Observable sono utilizzati per gestire i flussi di dati asincroni all'interno dell'applicazione Angular, consentendo una comunicazione reattiva tra componenti e servizi. */
import { LoggedUser, Movie, MovieDetail, filmAggiunto } from './home/home.component';
/* : Questa riga importa due interfacce, Movie e MovieDetail, dal file home.component.ts che si trova nella stessa cartella del servizio SearchService. Queste interfacce sono utilizzate per definire la struttura degli oggetti che rappresentano i film e i dettagli dei film all'interno dell'applicazione. */





@Injectable({/*  */
  providedIn: 'root',
}) /*  Questo è il decoratore Injectable, che viene applicato alla classe SearchService. Il decoratore indica che la classe SearchService può essere iniettata come un servizio all'interno dell'applicazione Angular. L'opzione providedIn: 'root' specifica che il servizio sarà disponibile in modo globale nell'applicazione, garantendo che venga utilizzata una singola istanza del servizio per l'intera applicazione (singleto */


export class SearchService {
  searchApiUrl = 'https://api.themoviedb.org/3/search/movie?api_key=f76d89b2bb26ca527b01f266350fe0ec&language=it-IT&page=1&query=';
  moviesSubject = new Subject<Movie[]>(); /*  viene creato un nuovo oggetto Subject e assegnato alla variabile moviesSubject. Un Subject è un tipo speciale di Observable che può anche emettere nuovi valori agli osservatori sottoscritti. Qui, il Subject è tipizzato con l'interfaccia Movie[], che indica che i valori emessi dal Subject saranno array di oggetti Movie. */

  movies$: Observable<Movie[]> = this.moviesSubject.asObservable();
  /* crea un Observable chiamato movies$ che permette ai componenti dell'applicazione di sottoscriversi e ricevere aggiornamenti sui film emessi dal moviesSubject, senza permettere loro di emettere nuovi valori. */

  initialMovies: Movie[] = []; // per memorizzare i film iniziali

  updateMovies(movies: Movie[]): void {
    // aggiorna sia moviesSubject che initialMovies
    this.moviesSubject.next(movies);
    this.initialMovies = movies;
  }/*  il metodo updateMovies permette di aggiornare il set di film nel servizio SearchService e di comunicare questo aggiornamento ai componenti che si sono sottoscritti all'Observable movies$, che è stato creato a partire da moviesSubject. */

/*   isOnline: Boolean = false; */
  constructor(private http: HttpClient) {}


  getMovieDetail(movieId: number): Observable<MovieDetail> {
    /* Questo metodo accetta un parametro movieId di tipo number e restituisce un Observable<MovieDetail>. Il metodo viene utilizzato per ottenere i dettagli di un film, inclusi i crediti, tramite l'API di The Movie Database (TMDb). */


    const detailUrl = `https://api.themoviedb.org/3/movie/${movieId}?api_key=f76d89b2bb26ca527b01f266350fe0ec&language=it-IT&append_to_response=credits`;
    /*  viene costruita la URL completa per la richiesta HTTP, utilizzando il movieId passato come parametro. La URL include l'endpoint dell'API per ottenere i dettagli di un film, la chiave API, la lingua (italiano) e l'opzione append_to_response=credits per includere anche i crediti del film nella risposta. */
    return this.http.get<MovieDetail>(detailUrl);
    /* metodo get() dell'istanza di HttpClient (in questo caso, this.http) per effettuare una richiesta HTTP GET all'URL costruita in precedenza. il metodo get() restituisce un Observable<MovieDetail>, che può essere sottoscritto dai componenti per ottenere i dettagli del film. */
  }


  searchMovies(query: string): void {
    if (query.trim() === '') {
      // se la query è vuota, ripristina la lista iniziale dei film
      this.moviesSubject.next(this.initialMovies);
      return;
      /*  metodo accetta un parametro query di tipo stringa e ha un tipo di ritorno void, il che significa che non restituisce alcun valore.

if (query.trim() === '') { return; }: Questa riga controlla se la stringa query è vuota o contiene solo spazi bianchi. Se è vuota, il metodo esce immediatamente senza effettuare alcuna ricerca. */
    
      
    }
    this.http.get<any>(this.searchApiUrl + query).subscribe( /*  Questa riga utilizza il servizio HttpClient per effettuare una richiesta HTTP GET all'URL dell'API di ricerca di TMDb, composta dalla proprietà this.searchApiUrl e dalla stringa query. Il metodo get() restituisce un Observable, a cui ci si può sottoscrivere per gestire la risposta dell'API. */
      (data) => {
        if (data.results) {
          this.moviesSubject.next(data.results);
        } else {
          this.moviesSubject.next([]);
        }
        /* if (data.results) { this.moviesSubject.next(data.results); }: Se i dati restituiti dall'API contengono una proprietà results (un array di oggetti film), il metodo chiama this.moviesSubject.next(data.results) per emettere l'array di film agli osservatori sottoscritti al corrispondente Observable, movies$.

else { this.moviesSubject.next([]); }: Se i dati restituiti dall'API non contengono una proprietà results, il metodo chiama this.moviesSubject.next([]) per emettere un array vuoto agli osservatori sottoscritti a movies$. */
      },
      (error) => {
        console.error('errore:', error);
      }
    );
  }



  wsJSONServerUsers = "http://localhost:3000/";

  accedi(data: any): Observable<any> {
    return this.http.post(this.wsJSONServerUsers + "login", data)
  }

  registrati(data: any): Observable<any> {
    return this.http.post(this.wsJSONServerUsers + "register", data)
  }

  
 /* getutente(){
  let userStorage = localStorage.getItem("user");// 

  if (userStorage != null) {
    let u = JSON.parse(userStorage);
    // return u;
    this.isOnline = true;
  } else {

    this.isOnline = false;    
    // return null;
    
  }

} */

  logout(){
    localStorage.clear();
  }

/* aggiunguAllaTuaLista sarebbe il bottone dei film  */
  aggiungiAllaTuaLista(movie: Movie) { /* aggiungere i film alla lista  */
    let utenteStorage = localStorage.getItem('user');

    if(utenteStorage) {
      let loggedUser = JSON.parse(utenteStorage); /*  parse per riportare i dati di local 
      storage sotto forma di oggetti  */
      let filmaAggiunto = {/* creo oggetto formato da film  e userid per poter recuperare i dati
      dal server  */
        film: movie,
        userId: loggedUser.user.id
      }

      this.http.post<any>("http://localhost:3000/" + "posts", filmaAggiunto, {/* quando faccio una chiamata post dobbiamo mettere sempre un body  */
        headers: new HttpHeaders({ /*  headers server per autorizzare  l utente  */
          "Authorization": "Bearer " + loggedUser.accessToken
        })
        }).subscribe({
          next: (res) =>  {
            console.log(res);
          }

      })
    }



  }

  ottieniListaAggiunti(): Observable<filmAggiunto[]>{
    let utenteStorage = localStorage.getItem('user');
    if(utenteStorage) {
      let LoggedUser = JSON.parse(utenteStorage);

      return this.http.get<filmAggiunto[]>("http://localhost:3000/" + "posts?userId=" + LoggedUser.user.id, { /* faccimp chiamte get colleghiamo indirizzo lalchost richiediamo il post di quell utente loggato */
        headers: new HttpHeaders({
          "Authorization": "Bearer " + LoggedUser.accessToken
        })
      })
    }

    return of([]);
  }
/*   deletePost(id: number): Observable<any> {
    let utenteStorage = localStorage.getItem('user');
    if (utenteStorage) {
      let LoggedUser = JSON.parse(utenteStorage);
  
      return this.http.delete(`${this.wsJSONServerUsers}posts/${id}`, {
        headers: new HttpHeaders({
          "Authorization": "Bearer " + LoggedUser.accessToken
        })
      });
    }
  
    return of([]);
  } */
  


  



}


