



<div class="movies-container">
  <div class="movie-card" *ngFor="let movie of movies">
    <img [src]="'https://image.tmdb.org/t/p/w500' + movie.poster_path" 
       (click)="getMovieDetail(movie.id)" /> <!-- Per ottenere l'URL completo dell'immagine del poster, è necessario combinare il poster_path con il prefisso URL fornito da TMDB, che è https://image.tmdb.org/t/p/w500. Quindi, la riga di codice in questione concatena il prefisso URL e il poster_path per ottenere l'URL completo dell'immagine del poster e lo utilizza come valore dell'attributo src dell'elemento img. -->
    <div class="movie-info">
      <h3>{{ movie.title }}</h3>
      <h6> Anno: {{ movie.release_date | date:'yyyy' }} </h6>
<!--       <h6>id: {{movie.id}}</h6> -->

      <p></p>
   <!--    <button (click)="aggiungiAllaLista(movie)">ACQUISTA</button> -->
    </div>
  </div>
</div>

<app-movie-detail [selectedMovie]="selectedMovie" (hideDetail)="hideMovieDetail()"></app-movie-detail>
