<br><br><br>
  <h3>Modulo di Contatto</h3>

<form [formGroup]="contattiForm" (ngSubmit)="onSubmit()" class="container mt-5">
    <div class="form-group">
      <label for="nome">Nome:</label>
      <input id="nome" type="text" formControlName="nome" class="form-control">
    </div>
    <div class="form-group">
      <label for="email">Email:</label>
      <input id="email" type="email" formControlName="email" class="form-control">
    </div>
    <div class="form-group">
      <label for="messaggio">Messaggio:</label>
      <textarea id="messaggio" formControlName="messaggio" class="form-control" rows="4"></textarea>
    </div>
    <button type="submit" [disabled]="!contattiForm.valid" class="btn btn-primary">Invia</button>
  </form>
  
  <br><br><br>